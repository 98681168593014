<template>
  <div>
    <b-table
      :data="data"
      :loading="loading"
      :per-page="perPage"
      :default-sort="defaultSort"
      :mobile-cards="false"
      :backend-sorting="backendSorting"
      :backend-pagination="backendPagination"
      :total="total"
      :current-page="currentPage"
      :debounce-search="300"
      striped
      hoverable
      paginated
      @page-change="handlePageChange"
      @sort="handleSort"
    >
      <slot name="columns" />
      <template #empty>
        <div class="has-text-centered">
          No Data Available
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'Table',

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    defaultSort: {
      type: Array,
      default: () => ['id', 'desc'],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 50,
    },
    backendSorting: {
      type: Boolean,
      default: false,
    },
    backendPagination: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },

  methods: {
    handlePageChange(page) {
      this.$emit('page-change', page);
    },

    handleSort(field, order) {
      this.$emit('sort', field, order);
    },
  },
};
</script>
